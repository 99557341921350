@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://use.typekit.net/jmp3nse.css");

body {
	font-family: "Playfair Display", serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
	color: #4b5563;
}

h1 {
	font-family: "bebas-neue", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 3rem;
	line-height: 3rem;
}

h4 {
	font-family: "bebas-neue", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 2rem;
	line-height: 2rem;
}

.canvas-container canvas {
	/* Apply 1250px height on mobile screens */
	@media (max-width: 768px) {
		width: 100%;
		height: 1340px !important;
	}
}

.gridLines,
.gridCircle {
	position: relative;
	z-index: 100;
}

.gridLines::after {
	content: "";
	position: absolute;
	top: 0;
	left: 25%;
	width: 1px;
	height: 100%;
	z-index: 13;
	background: #a6a6a6;
}

.gridLines::before {
	content: "";
	position: absolute;
	top: 0;
	left: 75%;
	width: 1px;
	height: 100%;
	z-index: 13;
	background: #a6a6a6;
}

.gridCircle::after {
	content: "";
	position: absolute;
	top: 0;
	left: 25%;
	width: 50%;
	height: 100%;
	z-index: 13;
	border: solid #a6a6a6 1px;
	border-radius: 50%;
}

.smallGridLines:first-child,
.smallGridLines:last-child {
	position: relative;
}

.smallGridLines:first-child:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 13;
	border: solid #a6a6a6 1px;
	border-radius: 50%;
}

.smallGridLines:last-child:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: 13;
	background: #a6a6a6;
}

.borderLines {
	position: relative;
}

.borderLines:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	z-index: 13;
	background: #a6a6a6;
}

.textUnderline {
	position: relative;
}

.textUnderline::after {
	content: "";
	position: absolute;
	top: -10px;
	left: 0;
	width: 20%;
	height: 2px;
	background: white;
}

.ripple-effect {
	width: 100%;
	height: 300px;
	overflow: hidden;
	position: relative;

	@media (max-width: 768px) {
		width: 100%;
		height: 100%;
	}
}

.ripple-effect img {
	width: 100%;
	height: 100%;
	transition: transform 0.3s ease;
	animation: none;
}

.ripple-effect:hover img {
	animation: ripple 0.8s ease-in-out;
}

@keyframes ripple {
	0% {
		transform: scale(1);
		filter: blur(0);
	}
	50% {
		transform: scale(1.05);
		filter: blur(2px);
	}
	100% {
		transform: scale(1);
		filter: blur(0);
	}
}

@keyframes wave {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-10px) translateX(5px) rotate(-2deg);
	}
	50% {
		transform: translateY(0) translateX(-5px) rotate(2deg);
	}
	75% {
		transform: translateY(10px) translateX(5px) rotate(-2deg);
	}
	100% {
		transform: translateY(0);
	}
}

.cursor-ring {
	position: absolute;
	width: 50px; /* Size of the ring */
	height: 50px;
	border: 2px solid #000; /* Ring border color */
	border-radius: 50%;
	pointer-events: none; /* Prevent interaction with elements behind the cursor */
	transform: translate(-50%, -50%); /* Center the ring at the cursor position */
	opacity: 0.8; /* Opacity of the ring */
	z-index: 9999; /* Make sure it appears above everything else */
	transition: transform 0.1s ease; /* Smooth the transition when moving */
}

/* Custom styles for the range input */
input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 10px; /* Adjust the width of the knob */
	height: 10px; /* Adjust the height of the knob */
	background-color: black; /* Make the knob black */
	border-radius: 50%; /* Make it round */
	cursor: pointer;
	border: none;
}

input[type="range"]::-moz-range-thumb {
	width: 16px; /* Adjust the width of the knob */
	height: 16px; /* Adjust the height of the knob */
	background-color: black; /* Make the knob black */
	border-radius: 50%; /* Make it round */
	cursor: pointer;
	border: none;
}

input[type="range"]:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 2px #00f; /* Add a focus effect if you want */
}

/* Hide all before and after styles on mobile */
@media (max-width: 768px) {
	.gridLines::before,
	.gridLines::after,
	.gridCircle::after,
	.smallGridLines:first-child::after,
	.smallGridLines:last-child::before,
	.borderLines::after,
	.textUnderline::after {
		content: none !important;
	}
}

.gridContainer {
	width: 100%;
	max-width: 100%; /* Ensures grid doesn't exceed the width of the container */
	overflow: hidden; /* Prevents horizontal scrolling */
}

.gridCircle {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden; /* Prevent horizontal overflow */
}

.ring-cursor {
	position: fixed;
	width: 80px; /* Adjust size */
	height: 80px; /* Adjust size */
	border: 2px solid #4b5563; /* White semi-transparent border */
	border-radius: 50%; /* Make it circular */
	pointer-events: none; /* Ignore clicks on the ring */
	transform: translate(-50%, -50%); /* Center the ring on the cursor */
	transition: transform 0.1s ease-out; /* Smooth transition */
	z-index: 200;
}
